import React from "react";
import {Route} from "react-router-dom";

import ROUTE_NAMES from "./util/routeNames";
import SignupPage from "../../authentication/page/signup/SignupPage";
import LoginPage from "../../authentication/page/login/LoginPage";
import ForgotPasswordPage from "../../authentication/page/forgot-password/ForgotPasswordPage";
import ChangeForgottenPasswordPage from "../../authentication/page/forgot-password/ChangeForgottenPasswordPage";

function getPublicRouteComponents() {
  return [
    <Route
      key={`route:=${ROUTE_NAMES.AUTH.LOGIN}`}
      exact={true}
      path={ROUTE_NAMES.AUTH.LOGIN}>
      <LoginPage />
    </Route>,

    <Route
      key={`route:=${ROUTE_NAMES.AUTH.FORGOT_PASSWORD}`}
      exact={true}
      path={ROUTE_NAMES.AUTH.FORGOT_PASSWORD}>
      <ForgotPasswordPage />
    </Route>,

    <Route
      key={`route:=${ROUTE_NAMES.AUTH.CHANGE_FORGOTTEN_PASSWORD}`}
      exact={true}
      path={ROUTE_NAMES.AUTH.CHANGE_FORGOTTEN_PASSWORD}>
      <ChangeForgottenPasswordPage />
    </Route>,

    <Route
      key={`route:=${ROUTE_NAMES.AUTH.SIGNUP}`}
      exact={true}
      path={ROUTE_NAMES.AUTH.SIGNUP}>
      <SignupPage />
    </Route>
  ];
}

export default getPublicRouteComponents;
