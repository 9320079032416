import {ReactComponent as Logo} from "../../../core/ui/icons/logo.svg";

import "./_auth-page-layout.scss";

import React from "react";
import BackButton from "../../../components/button/back/BackButton";
import AuthMobileHeaderBanner from "../../../core/ui/assets/images/auth-mobile-header-banner.png";

interface AuthPageLayoutProps {
  children: React.ReactNode;
  title: string;
  description?: string;
  backButtonProps?: {
    text: string;
    handleClick: VoidFunction;
    shouldDisplay: boolean;
  };
}

function AuthPageLayout({
  children,
  title,
  description,
  backButtonProps
}: AuthPageLayoutProps) {
  return (
    <div className={"auth-page-layout"}>
      <div className={"auth-page-layout-body"}>
        <Logo className={"ahvo-logo"} />

        <img
          src={AuthMobileHeaderBanner}
          alt={"Various photos of artists"}
          className={"auth-page-layout-mobile-header-image"}
        />

        {backButtonProps?.shouldDisplay && (
          <BackButton onClick={backButtonProps.handleClick}>
            {backButtonProps.text}
          </BackButton>
        )}

        <h1 className={"auth-page-layout-title"}>{title}</h1>

        {description && <p className={"auth-page-layout-description"}>{description}</p>}

        {children}
      </div>
    </div>
  );
}

export default AuthPageLayout;
