import {CancelToken} from "axios";

import {networkManager} from "../..";
import {
  AuthUserProfileModel,
  UpdateAuthUserRequestPayload,
  SignupRequestPayload,
  LoginRequestPayload,
  ForgotPasswordRequestPayload,
  ChangeForgottenPasswordRequestPayload
} from "./authenticationApiModels";
import apiHandler from "../../core/network-manager/apiHandler";

function getAuthUser() {
  return apiHandler<AuthUserProfileModel>(networkManager, "get", "/users/me/");
}

function updateAuthUser(
  payload: UpdateAuthUserRequestPayload,
  cancelToken?: CancelToken
) {
  return apiHandler<AuthUserProfileModel>(networkManager, "patch", "/users/me/", {
    payload,
    settings: {
      cancelToken
    }
  });
}

function signup(payload: SignupRequestPayload, cancelToken?: CancelToken) {
  return apiHandler<SignupRequestPayload>(
    networkManager,
    "post",
    "/users/registration/",
    {
      payload,
      settings: {
        cancelToken
      }
    }
  );
}

function login(payload: LoginRequestPayload, cancelToken?: CancelToken) {
  return apiHandler<SignupRequestPayload>(
    networkManager,
    "post",
    "/users/authentication/",
    {
      payload,
      settings: {
        cancelToken
      }
    }
  );
}

function sendForgotPasswordEmail(
  payload: ForgotPasswordRequestPayload,
  cancelToken?: CancelToken
) {
  return apiHandler(networkManager, "post", "/users/send-forgotten-password-email/", {
    payload,
    settings: {
      cancelToken
    }
  });
}

function changeForgottenPassword(
  payload: ChangeForgottenPasswordRequestPayload,
  cancelToken?: CancelToken
) {
  return apiHandler(networkManager, "post", "/users/change-forgotten-password/", {
    payload,
    settings: {
      cancelToken
    }
  });
}

export {
  getAuthUser,
  updateAuthUser,
  signup,
  login,
  sendForgotPasswordEmail,
  changeForgottenPassword
};
