import React from "react";
import {Link} from "react-router-dom";
import ROUTE_NAMES from "../../core/route/util/routeNames";

function SignupLink() {
  return (
    <div className={"signup-link"}>
      {"Don't have an account yet? "}
      <Link to={ROUTE_NAMES.AUTH.SIGNUP}>{"Sign Up"}</Link>
    </div>
  );
}

export default SignupLink;
