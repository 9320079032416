import "./_form-error-message.scss";

import React from "react";

interface FormErrorMessageProps {
  errorMessage: React.ReactNode;
}

function FormErrorMessage({errorMessage}: FormErrorMessageProps) {
  return <p className={"form-error-message"}>{errorMessage}</p>;
}

export default FormErrorMessage;
