import {DropdownOption, TypeaheadSelect} from "@hipo/react-ui-toolkit";
import React, {useState} from "react";

import {getLocations} from "../api/locationApi";

interface LocationTypeaheadProps {
  name: string;
  onSelect: (option: DropdownOption | null) => void;
  selectedOption?: DropdownOption | null;
  placeholder?: string;
}

function LocationTypeahead({
  name,
  placeholder = "search for country/city",
  selectedOption,
  onSelect
}: LocationTypeaheadProps) {
  const [locations, setLocations] = useState<DropdownOption[]>();

  return (
    <TypeaheadSelect
      customClassName={"location-typeahead"}
      selectedOptions={selectedOption ? [selectedOption] : []}
      dropdownOptions={locations || []}
      onSelect={handleLocationSelect}
      onTagRemove={handleLocationRemove}
      onKeywordChange={handleLocationSearch}
      typeaheadProps={{
        placeholder,
        name
      }}
    />
  );

  function handleLocationSelect(option: DropdownOption) {
    onSelect(option);
  }

  function handleLocationRemove() {
    onSelect(null);
  }

  function handleLocationSearch(value: string) {
    getLocations({query: value}).then((response) => {
      setLocations(
        response.data.map((location) => ({
          id: location.external_id!,
          title: location.name!,
          context: {
            external_id_source: location.external_id_source,
            external_id: location.external_id
          }
        }))
      );
    });
  }
}

export default LocationTypeahead;
