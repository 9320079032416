import React, {useReducer} from "react";
import {useParams, useHistory} from "react-router-dom";
import {Button, FormField, Input} from "@hipo/react-ui-toolkit";

import ROUTE_NAMES from "../../../../core/route/util/routeNames";
import {ValueOf} from "../../../../utils/typeUtils";
import changeForgottenPasswordFormStateReducer, {
  changeForgottenPasswordFormInitialState,
  CHANGE_FORGOTTEN_PASSWORD_FORM_STATE_INPUT_NAMES
} from "../../../reducer/changeForgottenPasswordFormStateReducer";
import {changeForgottenPassword} from "../../../api/authenticationApi";
import {generateSpecificFieldError} from "../../../../utils/error/errorUtils";
import useAsync from "../../../../utils/hooks/async/useAsync";
import Form from "../../../../components/form/Form";

function ChangeForgottenPasswordForm() {
  const {forgotten_password_key} = useParams();
  const history = useHistory();
  const [formState, dispatchFormAction] = useReducer(
    changeForgottenPasswordFormStateReducer,
    changeForgottenPasswordFormInitialState
  );

  const {errorInfo, isRequestPending} = useAsync(
    {
      handlerCreator: changeForgottenPassword,
      argumentGenerator() {
        return {
          new_password: formState.newPassword,
          forgotten_password_key
        };
      }
    },
    [formState.isSubmitted],
    {
      shouldMakeRequest() {
        return formState.isSubmitted;
      },

      onSuccess() {
        history.push(ROUTE_NAMES.AUTH.LOGIN);
      },

      onFailure() {
        dispatchFormAction({
          type: "isSubmitted",
          payload: false
        });
      }
    }
  );

  const getFieldError = generateSpecificFieldError(errorInfo);

  return (
    <Form
      customClassName={"auth-page-form change-forgotten-password-form"}
      values={formState}
      knownErrorKeys={["new_password"]}
      errorInfo={errorInfo}
      isSubmitAllowed={Boolean(formState.newPassword && formState.newPasswordVerify)}
      onSubmit={handleSubmitForm}>
      <FormField label={"NEW PASSWORD"} errorMessages={getFieldError("new_password")}>
        <Input
          name={"newPassword"}
          type={"password"}
          value={formState.newPassword}
          hasError={!!getFieldError("new_password")}
          onChange={handleFieldChange}
          placeholder={"Enter your password"}
        />
      </FormField>

      <FormField
        label={"VERIFY NEW PASSWORD"}
        errorMessages={getFieldError("new_password_verify")}>
        <Input
          name={"newPasswordVerify"}
          type={"password"}
          value={formState.newPasswordVerify}
          hasError={!!getFieldError("new_password_verify")}
          onChange={handleFieldChange}
          placeholder={"Verify your password"}
        />
      </FormField>

      <Button
        type={"submit"}
        customClassName={"blue"}
        onClick={handleSubmitForm}
        shouldDisplaySpinner={isRequestPending}
        isDisabled={!formState.newPassword || !formState.newPasswordVerify}>
        {"Submit"}
      </Button>
    </Form>
  );

  function handleFieldChange(event: React.SyntheticEvent<HTMLInputElement>) {
    dispatchFormAction({
      type: event.currentTarget.name as ValueOf<
        typeof CHANGE_FORGOTTEN_PASSWORD_FORM_STATE_INPUT_NAMES
      >,
      payload: event.currentTarget.value
    });
  }

  function handleSubmitForm() {
    dispatchFormAction({
      type: "isSubmitted",
      payload: true
    });
  }
}

export default ChangeForgottenPasswordForm;
