import React, {useState} from "react";
import {useHistory} from "react-router-dom";

import Page from "../../../main/page/Page";
import AuthPageLayout from "../layout/AuthPageLayout";
import ForgotPasswordForm from "./form/ForgotPasswordForm";
import ROUTE_NAMES from "../../../core/route/util/routeNames";

function ForgotPasswordPage() {
  const history = useHistory();
  const [isFormFeedbackVisible, setFormFeedbackVisibility] = useState(false);

  return (
    <Page metaTitle={"Forgot Password"} customClassName={"forgot-password-page"}>
      <AuthPageLayout
        title={generateLayoutTitle()}
        description={generateLayoutDescription()}
        backButtonProps={{
          text: "Back to Login",
          shouldDisplay: true,
          handleClick() {
            history.push(ROUTE_NAMES.AUTH.LOGIN);
          }
        }}>
        <ForgotPasswordForm
          isFormFeedbackVisible={isFormFeedbackVisible}
          setFormFeedbackVisibility={setFormFeedbackVisibility}
        />
      </AuthPageLayout>
    </Page>
  );

  function generateLayoutTitle() {
    let title = "Forgot Password";

    if (isFormFeedbackVisible) {
      title = "Check Your Email";
    }

    return title;
  }

  function generateLayoutDescription() {
    let description =
      "Enter email below and we will send you instructions to reset your password";

    if (isFormFeedbackVisible) {
      description =
        "We have sent intructions to reset your password. If you don’t see an email, please be sure to check other folders such as junk and spam.";
    }

    return description;
  }
}

export default ForgotPasswordPage;
