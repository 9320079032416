import {
  generateInitialAuthenticationState,
  TAuthenticationState
} from "../authenticationState";
import {TReduxActionWithPayload} from "../../../core/redux/models/action";

function authenticationStateReducer(
  state = generateInitialAuthenticationState(),
  action: TReduxActionWithPayload
): TAuthenticationState {
  switch (action.type) {
    case "SET_AUTHENTICATED_PROFILE": {
      return {
        ...state,
        authenticatedProfile: action.payload
      };
    }

    case "UPDATE_AUTHENTICATED_PROFILE": {
      return {
        ...state,
        authenticatedProfile: {
          ...state.authenticatedProfile!,
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
}

export {authenticationStateReducer};
