import {ValueOf} from "../../utils/typeUtils";

const LOGIN_FORM_STATE_TEXT_FIELDS = {
  EMAIL: "email",
  PASSWORD: "password"
} as const;

const loginFormInitialState = {
  [LOGIN_FORM_STATE_TEXT_FIELDS.EMAIL]: "",
  [LOGIN_FORM_STATE_TEXT_FIELDS.PASSWORD]: "",
  rememberMe: false,
  isSubmitted: false
};

type LoginFormStateReducerAction =
  | {
      type: ValueOf<typeof LOGIN_FORM_STATE_TEXT_FIELDS>;
      payload: string;
    }
  | {
      type: "rememberMe" | "isSubmitted";
      payload: boolean;
    };

function loginFormStateReducer(
  state: typeof loginFormInitialState,
  action: LoginFormStateReducerAction
): typeof loginFormInitialState {
  let newState = state;

  switch (action.type) {
    case LOGIN_FORM_STATE_TEXT_FIELDS.EMAIL:
    case LOGIN_FORM_STATE_TEXT_FIELDS.PASSWORD: {
      newState = {
        ...state,
        [action.type]: action.payload
      };

      break;
    }

    case "rememberMe":
    case "isSubmitted": {
      newState = {
        ...state,
        [action.type]: action.payload
      };

      break;
    }

    default:
      break;
  }

  return newState;
}

export default loginFormStateReducer;
export {LOGIN_FORM_STATE_TEXT_FIELDS, loginFormInitialState};
