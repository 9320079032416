import {ReactComponent as DoneIcon} from "../../core/ui/icons/done-2.svg";

import "./_floating-message.scss";

import React, {useReducer, useEffect} from "react";
import classNames from "classnames";
import {createPortal} from "react-dom";

import FloatingMessageContext, {
  floatingMessageStateReducer,
  initialFloatingMessageState
} from "./util/FloatingMessageContext";

function FloatingMessageProvider({children}: {children: React.ReactNode}) {
  const [{message, isVisible, type, timeout}, dispatch] = useReducer(
    floatingMessageStateReducer,
    initialFloatingMessageState
  );
  const containerClassName = classNames("floating-message", {
    "is-error": type === "ERROR",
    visible: isVisible
  });
  let defaultMessage = "Changes saved successfully!";

  if (type === "ERROR") {
    defaultMessage = "Something went wrong";
  }

  useEffect(() => {
    let timeoutId: any;

    if (isVisible) {
      timeoutId = setTimeout(() => {
        dispatch({
          type: "HIDE"
        });
      }, timeout);
    }

    return () => clearTimeout(timeoutId);
  }, [dispatch, isVisible, timeout]);

  const messageNode = createPortal(
    <p className={containerClassName}>
      {type === "SUCCESS" && (
        <DoneIcon className={"floating-message-icon"} aria-hidden={true} />
      )}

      <span data-testid={"FloatingMessage.text"} className={"floating-message-text"}>
        {message || defaultMessage}
      </span>
    </p>,
    document.getElementById("floating-message-root")!
  );

  return (
    <FloatingMessageContext.Provider value={{dispatch}}>
      {messageNode}

      {children}
    </FloatingMessageContext.Provider>
  );
}

export default FloatingMessageProvider;
