import {ReactComponent as LeftArrowIcon} from "../../../core/ui/icons/left-arrow.svg";

import React from "react";
import {Button, ButtonProps} from "@hipo/react-ui-toolkit";
import classNames from "classnames";

function BackButton({children, ...props}: ButtonProps) {
  return (
    <Button {...props} customClassName={classNames("back-button", props.customClassName)}>
      <LeftArrowIcon className={"left-arrow"} />

      {children}
    </Button>
  );
}

export default BackButton;
