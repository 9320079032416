import {ValueOf} from "../../utils/typeUtils";

const FORGOT_PASSWORD_FORM_STATE_INPUT_NAMES = {
  EMAIL: "email"
} as const;

const forgotPasswordFormInitialState = {
  [FORGOT_PASSWORD_FORM_STATE_INPUT_NAMES.EMAIL]: "",
  isSubmitted: false
};

type ForgotPasswordFormStateReducerAction =
  | {
      type: ValueOf<typeof FORGOT_PASSWORD_FORM_STATE_INPUT_NAMES>;
      payload: string;
    }
  | {
      type: "isSubmitted";
      payload: boolean;
    };

function forgotPasswordFormStateReducer(
  state: typeof forgotPasswordFormInitialState,
  action: ForgotPasswordFormStateReducerAction
): typeof forgotPasswordFormInitialState {
  let newState = state;

  switch (action.type) {
    case FORGOT_PASSWORD_FORM_STATE_INPUT_NAMES.EMAIL:
    case "isSubmitted": {
      newState = {
        ...state,
        [action.type]: action.payload
      };

      break;
    }

    default:
      break;
  }

  return newState;
}

export default forgotPasswordFormStateReducer;
export {FORGOT_PASSWORD_FORM_STATE_INPUT_NAMES, forgotPasswordFormInitialState};
