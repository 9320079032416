import {ReactComponent as RevealIcon} from "../../../../core/ui/icons/visibility-on.svg";
import {ReactComponent as HideIcon} from "../../../../core/ui/icons/visibility-off.svg";

import React, {useReducer} from "react";
import {useDispatch} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
  Button,
  FormField,
  Input,
  CheckboxInput,
  PasswordInput
} from "@hipo/react-ui-toolkit";

import ROUTE_NAMES from "../../../../core/route/util/routeNames";
import {ValueOf} from "../../../../utils/typeUtils";
import loginFormStateReducer, {
  loginFormInitialState,
  LOGIN_FORM_STATE_TEXT_FIELDS
} from "../../../reducer/loginFormStateReducer";
import {login, getAuthUser} from "../../../api/authenticationApi";
import {generateSpecificFieldError} from "../../../../utils/error/errorUtils";
import useAsync from "../../../../utils/hooks/async/useAsync";
import SignupLink from "../../../components/SignupLink";
import Form from "../../../../components/form/Form";

function LoginForm() {
  const history = useHistory();
  const dispatchReduxAction = useDispatch();

  const [formState, dispatchFormAction] = useReducer(
    loginFormStateReducer,
    loginFormInitialState
  );

  const {errorInfo, isRequestPending} = useAsync(
    {
      handlerCreator: login,
      argumentGenerator() {
        return {
          email: formState.email,
          password: formState.password,
          remember_me: formState.rememberMe
        };
      }
    },
    [formState.isSubmitted],
    {
      shouldMakeRequest() {
        return formState.isSubmitted;
      },

      onSuccess() {
        // Login endpoint doesn't provide profile data
        // We needed to request `/users/me` to gather the profile
        getAuthUser().then((response) => {
          dispatchReduxAction({
            type: "SET_AUTHENTICATED_PROFILE",
            payload: response.data
          });

          if (!response.data.is_onboarding_creative_field_selection_completed) {
            history.push(ROUTE_NAMES.ONBOARDING.TUNE_YOUR_FEED);
          } else if (!response.data.is_onboarding_style_selection_completed) {
            history.push(ROUTE_NAMES.ONBOARDING.CHOOSE_YOUR_STYLES);
          } else if (!response.data.is_onboarding_user_suggestion_completed) {
            history.push(ROUTE_NAMES.ONBOARDING.FOLLOW_SUGGESTIONS);
          } else if (response.data.is_onboarding_user_suggestion_completed) {
            history.push(ROUTE_NAMES.TIMELINE.ROOT);
          }
        });
      },

      onFinally() {
        dispatchFormAction({
          type: "isSubmitted",
          payload: false
        });
      }
    }
  );

  const getFieldError = generateSpecificFieldError(errorInfo);

  return (
    <Form
      customClassName={"auth-page-form"}
      values={formState}
      knownErrorKeys={["email", "password", "remember_me"]}
      errorInfo={errorInfo}
      onSubmit={handleSubmitForm}>
      <FormField label={"EMAIL"} errorMessages={getFieldError("email")}>
        <Input
          name={"email"}
          type={"email"}
          value={formState.email}
          hasError={!!getFieldError("email")}
          onChange={handleFieldChange}
          placeholder={"Enter your email"}
        />
      </FormField>

      <FormField label={"PASSWORD"} errorMessages={getFieldError("password")}>
        <PasswordInput
          name={"password"}
          value={formState.password}
          hasError={!!getFieldError("password")}
          onChange={handleFieldChange}
          placeholder={"Enter your password"}
          hideIcon={<HideIcon />}
          revealIcon={<RevealIcon />}
        />
      </FormField>

      <div className={"paired-form-fields"}>
        <FormField>
          <CheckboxInput
            onSelect={handleRememberMeChange}
            isSelected={formState.rememberMe}
            item={{
              id: "rememberMe",
              content: "Remember me",
              inputProps: {
                name: "rememberMe",
                value: "rememberMe",
                htmlFor: "rememberMe"
              }
            }}
          />
        </FormField>

        <Link to={ROUTE_NAMES.AUTH.FORGOT_PASSWORD} className={"forgot-password-link"}>
          {"Forgot Password?"}
        </Link>
      </div>

      <Button
        type={"submit"}
        customClassName={"blue"}
        onClick={handleSubmitForm}
        shouldDisplaySpinner={isRequestPending}>
        {"Sign in"}
      </Button>

      <SignupLink />
    </Form>
  );

  function handleFieldChange(event: React.SyntheticEvent<HTMLInputElement>) {
    dispatchFormAction({
      type: event.currentTarget.name as ValueOf<typeof LOGIN_FORM_STATE_TEXT_FIELDS>,
      payload: event.currentTarget.value
    });
  }

  function handleRememberMeChange() {
    dispatchFormAction({
      type: "rememberMe",
      payload: !formState.rememberMe
    });
  }

  function handleSubmitForm() {
    dispatchFormAction({
      type: "isSubmitted",
      payload: true
    });
  }
}

export default LoginForm;
