import {DropdownOption} from "@hipo/react-ui-toolkit";
import {ValueOf} from "../../utils/typeUtils";

const SIGNUP_FORM_STATE_INPUT_NAMES = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  USERNAME: "username",
  EMAIL: "email",
  PASSWORD: "password",
  VERIFY_PASSWORD: "verifyPassword"
} as const;

const signupFormInitialState = {
  [SIGNUP_FORM_STATE_INPUT_NAMES.FIRST_NAME]: "",
  [SIGNUP_FORM_STATE_INPUT_NAMES.LAST_NAME]: "",
  [SIGNUP_FORM_STATE_INPUT_NAMES.USERNAME]: "",
  [SIGNUP_FORM_STATE_INPUT_NAMES.EMAIL]: "",
  [SIGNUP_FORM_STATE_INPUT_NAMES.PASSWORD]: "",
  [SIGNUP_FORM_STATE_INPUT_NAMES.VERIFY_PASSWORD]: "",
  location: null as DropdownOption | null,
  isSubmitted: false,
  isSubmitAllowed: false
};

type SignupFormStateReducerAction =
  | {
      type: ValueOf<typeof SIGNUP_FORM_STATE_INPUT_NAMES>;
      payload: string;
    }
  | {
      type: "location";
      payload: DropdownOption | null;
    }
  | {
      type: "isSubmitted";
      payload: boolean;
    };

function signupFormStateReducer(
  state: typeof signupFormInitialState,
  action: SignupFormStateReducerAction
): typeof signupFormInitialState {
  let newState = state;

  switch (action.type) {
    case SIGNUP_FORM_STATE_INPUT_NAMES.FIRST_NAME:
    case SIGNUP_FORM_STATE_INPUT_NAMES.LAST_NAME:
    case SIGNUP_FORM_STATE_INPUT_NAMES.USERNAME:
    case SIGNUP_FORM_STATE_INPUT_NAMES.EMAIL:
    case SIGNUP_FORM_STATE_INPUT_NAMES.PASSWORD:
    case SIGNUP_FORM_STATE_INPUT_NAMES.VERIFY_PASSWORD: {
      newState = {
        ...state,
        [action.type]: action.payload
      };

      break;
    }

    case "location": {
      newState = {
        ...state,
        location: action.payload
      };

      break;
    }

    case "isSubmitted": {
      newState = {
        ...state,
        isSubmitted: action.payload
      };

      break;
    }

    default:
      break;
  }

  if (newState !== state) {
    newState.isSubmitAllowed = Boolean(
      newState.firstName &&
        newState.lastName &&
        newState.email &&
        newState.username &&
        newState.password &&
        newState.verifyPassword
    );
  }

  return newState;
}

export default signupFormStateReducer;
export {SIGNUP_FORM_STATE_INPUT_NAMES, signupFormInitialState};
