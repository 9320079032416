import "./_forgot-password-form.scss";

import React, {useReducer} from "react";
import {Button, FormField, Input} from "@hipo/react-ui-toolkit";

import {ValueOf} from "../../../../utils/typeUtils";
import forgotPasswordFormStateReducer, {
  forgotPasswordFormInitialState,
  FORGOT_PASSWORD_FORM_STATE_INPUT_NAMES
} from "../../../reducer/forgotPasswordFormStateReducer";
import {sendForgotPasswordEmail} from "../../../api/authenticationApi";
import {generateSpecificFieldError} from "../../../../utils/error/errorUtils";
import useAsync from "../../../../utils/hooks/async/useAsync";
import Form from "../../../../components/form/Form";

interface ForgotPasswordFormProps {
  isFormFeedbackVisible: boolean;
  setFormFeedbackVisibility: (visibility: boolean) => void;
}

function ForgotPasswordForm({
  isFormFeedbackVisible,
  setFormFeedbackVisibility
}: ForgotPasswordFormProps) {
  const [formState, dispatchFormAction] = useReducer(
    forgotPasswordFormStateReducer,
    forgotPasswordFormInitialState
  );

  const {errorInfo, isRequestPending} = useAsync(
    {
      handlerCreator: sendForgotPasswordEmail,
      argumentGenerator() {
        return {
          email: formState.email
        };
      }
    },
    [formState.isSubmitted],
    {
      shouldMakeRequest() {
        return formState.isSubmitted;
      },

      onSuccess() {
        setFormFeedbackVisibility(true);
      },

      onFinally() {
        dispatchFormAction({
          type: "isSubmitted",
          payload: false
        });
      }
    }
  );

  const getFieldError = generateSpecificFieldError(errorInfo);

  return (
    <Form
      customClassName={"auth-page-form forgot-password-form"}
      values={formState}
      knownErrorKeys={["email"]}
      errorInfo={errorInfo}
      onSubmit={handleSubmitForm}>
      <FormField label={"EMAIL"} errorMessages={getFieldError("email")}>
        <Input
          name={"email"}
          type={"email"}
          value={formState.email}
          hasError={!!getFieldError("email")}
          onChange={handleFieldChange}
          placeholder={"Enter your email"}
          isDisabled={isFormFeedbackVisible}
        />
      </FormField>

      {isFormFeedbackVisible && (
        <div className={"paired-form-fields"}>
          <span>{"you didn’t receive an email?"}</span>

          <Button customClassName={"link-button"} onClick={handleSentAgainClick}>
            {"Sent Again"}
          </Button>
        </div>
      )}

      {!isFormFeedbackVisible && (
        <Button
          customClassName={"blue"}
          onClick={handleSubmitForm}
          shouldDisplaySpinner={isRequestPending}
          isDisabled={!formState.email}>
          {"Submit"}
        </Button>
      )}
    </Form>
  );

  function handleFieldChange(event: React.SyntheticEvent<HTMLInputElement>) {
    dispatchFormAction({
      type: event.currentTarget.name as ValueOf<
        typeof FORGOT_PASSWORD_FORM_STATE_INPUT_NAMES
      >,
      payload: event.currentTarget.value
    });
  }

  function handleSubmitForm() {
    dispatchFormAction({
      type: "isSubmitted",
      payload: true
    });
  }

  function handleSentAgainClick() {
    setFormFeedbackVisibility(false);
  }
}

export default ForgotPasswordForm;
