const ROOT_ROUTE = "/";

const ROUTE_NAMES = {
  ROOT: ROOT_ROUTE,

  AUTH: {
    LOGIN: `${ROOT_ROUTE}auth/login`,
    SIGNUP: `${ROOT_ROUTE}auth/signup`,
    FORGOT_PASSWORD: `${ROOT_ROUTE}auth/forgot-password`,
    CHANGE_FORGOTTEN_PASSWORD: `${ROOT_ROUTE}auth/change-forgotten-password/:forgotten_password_key`
  },

  ONBOARDING: {
    TUNE_YOUR_FEED: `${ROOT_ROUTE}onboarding/tune-your-feed`,
    CHOOSE_YOUR_STYLES: `${ROOT_ROUTE}onboarding/choose-your-styles`,
    FOLLOW_SUGGESTIONS: `${ROOT_ROUTE}onboarding/follow-suggestions`
  },

  TIMELINE: {
    ROOT: `${ROOT_ROUTE}timeline`,
    FOLLOW_SUGGESTIONS: `${ROOT_ROUTE}follow-suggestions/:feedType`
  },

  USER: {
    ME: `${ROOT_ROUTE}user/me`,
    MY_ARTIST_PROFILE: `${ROOT_ROUTE}user/me/artist`,
    ARTIST_PROFILE: `${ROOT_ROUTE}user/:username/artist`,
    PROFILE: `${ROOT_ROUTE}user/:username`,
    EDIT_PROFILE: `${ROOT_ROUTE}user/me/edit`
  },

  ARTWORK: {
    CREATE: `${ROOT_ROUTE}artwork/create`,
    DETAIL: `${ROOT_ROUTE}artwork/:artworkId`
  },

  ORDER: {
    ROOT: `${ROOT_ROUTE}orders`,
    DETAIL: `${ROOT_ROUTE}orders/:orderId`
  },

  SALE: {
    ROOT: `${ROOT_ROUTE}sales`,
    DETAIL: `${ROOT_ROUTE}sales/:orderId`
  }
} as const;

export default ROUTE_NAMES;
