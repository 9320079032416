import "./_login-page.scss";

import React from "react";

import Page from "../../../main/page/Page";
import AuthPageLayout from "../layout/AuthPageLayout";
import LoginForm from "./form/LoginForm";

function LoginPage() {
  return (
    <Page metaTitle={"Sign in"} customClassName={"login-page"}>
      <AuthPageLayout
        title={"Imagine. Interact. Inspire."}
        description={
          "Socialize and collaborate with fellow creatives. Market and sell your work to start making money."
        }>
        <LoginForm />
      </AuthPageLayout>
    </Page>
  );
}

export default LoginPage;
