import "./_signup-page.scss";

import React, {useState} from "react";

import Page from "../../../main/page/Page";
import AuthPageLayout from "../layout/AuthPageLayout";
import SignupForm from "./form/SignupForm";

function SignupPage() {
  const [isInfoFormVisible, setInfoFormVisibility] = useState(false);

  return (
    <Page metaTitle={"Sign up"} customClassName={"sign-up-page"}>
      <AuthPageLayout
        title={generateLayoutTitle()}
        backButtonProps={{
          shouldDisplay: isInfoFormVisible,
          text: "BACK",
          handleClick() {
            setInfoFormVisibility(false);
          }
        }}>
        <SignupForm
          isInfoFormVisible={isInfoFormVisible}
          setInfoFormVisibility={setInfoFormVisibility}
        />
      </AuthPageLayout>
    </Page>
  );

  function generateLayoutTitle() {
    let title = "Sign Up";

    if (isInfoFormVisible) {
      title = "Complete Your Information";
    }

    return title;
  }
}

export default SignupPage;
