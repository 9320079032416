import {createContext, Dispatch, ReducerAction} from "react";

import {DEFAULT_FLOATING_MESSAGE_TIMEOUT} from "./floatingMessageConstants";

type FloatingMessageType = "SUCCESS" | "ERROR";

type FloatingMessageStateReducerAction =
  | {
      type: "DISPLAY";
      payload: {
        type: FloatingMessageType;
        message?: string;
        timeout?: number;
      };
    }
  | {
      type: "HIDE";
    };

type FloatingMessageStateReducer = typeof floatingMessageStateReducer;

const initialFloatingMessageState = {
  isVisible: false,
  message: "",
  timeout: DEFAULT_FLOATING_MESSAGE_TIMEOUT,
  type: "SUCCESS" as FloatingMessageType
};

function floatingMessageStateReducer(
  state = initialFloatingMessageState,
  action: FloatingMessageStateReducerAction
) {
  let newFiltersState;

  switch (action.type) {
    case "DISPLAY": {
      const {timeout = DEFAULT_FLOATING_MESSAGE_TIMEOUT, message = ""} = action.payload;

      newFiltersState = {
        ...action.payload,
        message,
        timeout,
        isVisible: true
      };
      break;
    }

    case "HIDE": {
      newFiltersState = {
        ...initialFloatingMessageState
      };
      break;
    }

    default:
      newFiltersState = state;
  }

  return newFiltersState;
}

const contextInitialValue: {
  dispatch: Dispatch<ReducerAction<FloatingMessageStateReducer>>;
} = {
  dispatch: () => undefined
};

const FloatingMessageContext = createContext(contextInitialValue);

export default FloatingMessageContext;
export {floatingMessageStateReducer, initialFloatingMessageState};
