import React from "react";

import Page from "../../../main/page/Page";
import AuthPageLayout from "../layout/AuthPageLayout";
import ChangeForgottenPasswordForm from "./form/ChangeForgottenPasswordForm";

function ChangeForgottenPasswordPage() {
  return (
    <Page
      metaTitle={"Change Password"}
      customClassName={"change-forgotten-password-page"}>
      <AuthPageLayout title={"Set New Password"}>
        <ChangeForgottenPasswordForm />
      </AuthPageLayout>
    </Page>
  );
}

export default ChangeForgottenPasswordPage;
