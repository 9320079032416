import {CancelToken} from "axios";

import apiHandler from "../../core/network-manager/apiHandler";
import {networkManager} from "../..";
import {Location} from "./locationApiModels";

const LOCATIONS_API_ROOT = "/locations";

function getLocations(params: {query: string}, cancelToken?: CancelToken) {
  return apiHandler<Location[]>(
    networkManager,
    "get",
    `${LOCATIONS_API_ROOT}/autocomplete/`,
    {
      settings: {
        params,
        cancelToken
      }
    }
  );
}

export {getLocations};
