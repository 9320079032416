import {ValueOf} from "../../utils/typeUtils";

const CHANGE_FORGOTTEN_PASSWORD_FORM_STATE_INPUT_NAMES = {
  NEW_PASSWORD: "newPassword",
  NEW_PASSWORD_VERIFY: "newPasswordVerify"
} as const;

const changeForgottenPasswordFormInitialState = {
  [CHANGE_FORGOTTEN_PASSWORD_FORM_STATE_INPUT_NAMES.NEW_PASSWORD]: "",
  [CHANGE_FORGOTTEN_PASSWORD_FORM_STATE_INPUT_NAMES.NEW_PASSWORD_VERIFY]: "",
  isSubmitted: false
};

type ChangeForgottenPasswordFormStateReducerAction =
  | {
      type: ValueOf<typeof CHANGE_FORGOTTEN_PASSWORD_FORM_STATE_INPUT_NAMES>;
      payload: ValueOf<typeof changeForgottenPasswordFormInitialState>;
    }
  | {
      type: "isSubmitted";
      payload: boolean;
    };

function changeForgottenPasswordFormStateReducer(
  state: typeof changeForgottenPasswordFormInitialState,
  action: ChangeForgottenPasswordFormStateReducerAction
): typeof changeForgottenPasswordFormInitialState {
  let newState = state;

  switch (action.type) {
    case CHANGE_FORGOTTEN_PASSWORD_FORM_STATE_INPUT_NAMES.NEW_PASSWORD:
    case CHANGE_FORGOTTEN_PASSWORD_FORM_STATE_INPUT_NAMES.NEW_PASSWORD_VERIFY: {
      newState = {
        ...state,
        [action.type]: action.payload
      };

      break;
    }

    case "isSubmitted": {
      newState = {
        ...state,
        isSubmitted: action.payload
      };

      break;
    }

    default:
      break;
  }

  return newState;
}

export default changeForgottenPasswordFormStateReducer;
export {
  CHANGE_FORGOTTEN_PASSWORD_FORM_STATE_INPUT_NAMES,
  changeForgottenPasswordFormInitialState
};
