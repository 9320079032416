/*
As of Babel 7.4.0, @babel/polyfill package has been deprecated in favor of directly including 
- core-js/stable (to polyfill ECMAScript features)
- regenerator-runtime/runtime (needed to use transpiled generator functions):
*/
import "core-js/stable";
import "regenerator-runtime/runtime";

import "@hipo/react-ui-toolkit/dist/main.css";

// Defaults
import "./core/ui/style/_font.scss";
import "./core/ui/style/reference/_color.scss";
import "./core/ui/style/util/_helper.scss";

// Overrides for components from @hipo/react-ui-toolkit
import "./components/input/_input.scss";
import "./components/input/checkbox/_checkbox.scss";
import "./components/button/_button.scss";
import "./components/form/field/_form-field.scss";
import "./components/tab/_tab.scss";
import "./components/select/typeahead/_typeahead-select.scss";
import "./components/dropdown/_dropdown.scss";
import "./components/dropdown/list/_dropdown-list.scss";

import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";

import RootApp from "./core/root-app/RootApp";
import NetworkManager from "./core/network-manager/NetworkManager";
import {createReduxStore} from "./core/redux/storeUtils";
import {ReduxStoreShape} from "./core/redux/models/store";
import {getAuthUser} from "./authentication/api/authenticationApi";
import {getNetworkBaseUrl} from "./core/network-manager/networkUtils";
import {initSentry, setUserContextForSentry} from "./core/sentryHandler";

initSentry();

const networkManager = new NetworkManager({
  baseURL: getNetworkBaseUrl()
});

getAuthUser()
  .then((response) => {
    const profileData = response.data;

    setUserContextForSentry({
      id: profileData.id,
      email: profileData.email
    });
    bootstrapApp({
      authenticationState: {
        authenticatedProfile: profileData
      }
    });
  })
  .catch(() => {
    bootstrapApp({});
  });

function bootstrapApp(initialStoreState: Partial<ReduxStoreShape> = {}) {
  const reduxStore = createReduxStore(initialStoreState);

  ReactDOM.render(
    <Provider store={reduxStore}>
      <RootApp />
    </Provider>,
    document.getElementById("root")
  );
}

export {networkManager};
