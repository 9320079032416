import {ReactComponent as AhvoLogo} from "../../core/ui/icons/logo.svg";

import "./_route-loading.scss";

import React from "react";
import {Spinner} from "@hipo/react-ui-toolkit";

function RouteLoading() {
  return (
    <div className={"route-loading"}>
      <div className={"route-loading-body"}>
        <AhvoLogo />

        <Spinner spinnerColor={"black"} backgroundColor={"white"} />
      </div>
    </div>
  );
}

export default RouteLoading;
