import {ReactComponent as RevealIcon} from "../../../../core/ui/icons/visibility-on.svg";
import {ReactComponent as HideIcon} from "../../../../core/ui/icons/visibility-off.svg";

import React, {useReducer} from "react";
import {useHistory} from "react-router-dom";
import {
  Button,
  DropdownOption,
  FormField,
  Input,
  PasswordInput
} from "@hipo/react-ui-toolkit";

import ROUTE_NAMES from "../../../../core/route/util/routeNames";
import {ValueOf} from "../../../../utils/typeUtils";
import {SignupRequestPayload} from "../../../api/authenticationApiModels";
import {generateSpecificFieldError} from "../../../../utils/error/errorUtils";
import {signup} from "../../../api/authenticationApi";
import signupFormStateReducer, {
  signupFormInitialState,
  SIGNUP_FORM_STATE_INPUT_NAMES
} from "../../../reducer/signupFormStateReducer";
import useAsync from "../../../../utils/hooks/async/useAsync";
import Form from "../../../../components/form/Form";
import LoginLink from "../../../components/LoginLink";
import LocationTypeahead from "../../../../location/typeahead/LocationTypeahead";

interface SignupFormProps {
  isInfoFormVisible: boolean;
  setInfoFormVisibility: (visibility: boolean) => void;
}

function SignupForm({isInfoFormVisible, setInfoFormVisibility}: SignupFormProps) {
  const [formState, dispatchFormAction] = useReducer(
    signupFormStateReducer,
    signupFormInitialState
  );
  const history = useHistory();

  const {errorInfo, isRequestPending} = useAsync(
    {
      handlerCreator: signup,
      argumentGenerator() {
        const params: SignupRequestPayload = {
          first_name: formState.firstName,
          last_name: formState.lastName,
          username: formState.username,
          email: formState.email,
          password: formState.password
        };

        if (formState.location) {
          params.location = formState.location.context;
        }

        return params;
      }
    },
    [formState.isSubmitted],
    {
      shouldMakeRequest() {
        return formState.isSubmitted;
      },

      onSuccess() {
        history.push(ROUTE_NAMES.AUTH.LOGIN);
      },

      onFinally() {
        dispatchFormAction({
          type: "isSubmitted",
          payload: false
        });
      }
    }
  );

  const getFieldError = generateSpecificFieldError(errorInfo);

  return (
    <Form
      customClassName={"auth-page-form"}
      onSubmit={handleSubmitForm}
      values={formState}
      errorInfo={errorInfo}
      knownErrorKeys={[
        "email",
        "password",
        "first_name",
        "last_name",
        "username",
        "location"
      ]}>
      {!isInfoFormVisible && (
        <>
          <FormField label={"EMAIL *"} errorMessages={getFieldError("email")}>
            <Input
              name={"email"}
              type={"email"}
              value={formState.email}
              onChange={handleFieldChange}
              placeholder={"Enter your email"}
              hasError={!!getFieldError("email")}
            />
          </FormField>

          <FormField label={"PASSWORD *"} errorMessages={getFieldError("password")}>
            <PasswordInput
              name={"password"}
              value={formState.password}
              onChange={handleFieldChange}
              placeholder={"Enter your password"}
              hasError={!!getFieldError("password")}
              hideIcon={<HideIcon />}
              revealIcon={<RevealIcon />}
            />
          </FormField>

          <FormField label={"VERIFY PASSWORD *"}>
            <PasswordInput
              name={"verifyPassword"}
              value={formState.verifyPassword}
              onChange={handleFieldChange}
              placeholder={"Enter your password"}
              hideIcon={<HideIcon />}
              revealIcon={<RevealIcon />}
            />
          </FormField>

          <Button customClassName={"blue"} type={"button"} onClick={handleSubmitForm}>
            {"Sign up"}
          </Button>

          <p className={"sign-up-page-legal-description"}>
            {`By signing up you concest to Ahvo storing the information you’re submitting so
            you can have a great experience while browsing. You also agree to the “Terms &
            Conditions” and “Privacy Policy”`}
          </p>

          <LoginLink />
        </>
      )}

      {isInfoFormVisible && (
        <>
          <div className={"paired-form-fields"}>
            <FormField label={"FIRST NAME *"} errorMessages={getFieldError("first_name")}>
              <Input
                name={"firstName"}
                value={formState.firstName}
                onChange={handleFieldChange}
                placeholder={"Write your first name"}
                hasError={!!getFieldError("first_name")}
              />
            </FormField>

            <FormField label={"LAST NAME *"} errorMessages={getFieldError("last_name")}>
              <Input
                name={"lastName"}
                value={formState.lastName}
                onChange={handleFieldChange}
                placeholder={"Write your last name"}
                hasError={!!getFieldError("last_name")}
              />
            </FormField>
          </div>

          <FormField label={"USERNAME *"} errorMessages={getFieldError("username")}>
            <Input
              name={"username"}
              value={formState.username}
              onChange={handleFieldChange}
              placeholder={"Write your @username"}
              hasError={!!getFieldError("username")}
            />
          </FormField>

          <FormField label={"CITY *"} errorMessages={getFieldError("location")}>
            <LocationTypeahead
              name={"location"}
              placeholder={"Write the name of your city"}
              selectedOption={formState.location}
              onSelect={handleLocationSelect}
            />
          </FormField>

          <Button
            customClassName={"blue"}
            type={"submit"}
            shouldDisplaySpinner={isRequestPending}
            isDisabled={!formState.isSubmitAllowed}>
            {"Next"}
          </Button>
        </>
      )}
    </Form>
  );

  function handleFieldChange(event: React.SyntheticEvent<HTMLInputElement>) {
    dispatchFormAction({
      type: event.currentTarget.name as ValueOf<typeof SIGNUP_FORM_STATE_INPUT_NAMES>,
      payload: event.currentTarget.value
    });
  }

  function handleLocationSelect(option: DropdownOption | null) {
    dispatchFormAction({
      type: "location",
      payload: option
    });
  }

  function handleSubmitForm() {
    if (isInfoFormVisible) {
      dispatchFormAction({
        type: "isSubmitted",
        payload: true
      });
    } else {
      setInfoFormVisibility(true);
    }
  }
}

export default SignupForm;
