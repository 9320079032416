import "../ui/style/_overrides.scss";

import {hot} from "react-hot-loader/root";
import React, {Suspense} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import {isDevelopmentEnv} from "../../utils/globalVariables";
import getPublicRouteComponents from "../route/publicRoutes";
import getProtectedRouteComponents from "../route/protectedRoutes";
import {getPublicFallbackRoutes} from "../route/fallbackRoutes";
import RouteLoading from "../../components/route-loading/RouteLoading";
import ErrorBoundary from "../error/ErrorBoundary";
import FloatingMessageProvider from "../../components/floating-message/FloatingMessageProvider";

function RootApp() {
  return (
    <BrowserRouter basename={"/"}>
      <Route path={"/"} component={AppWithErrorBoundary} />
    </BrowserRouter>
  );
}

function AppWithErrorBoundary() {
  return (
    <React.StrictMode>
      <Suspense fallback={<RouteLoading />}>
        <FloatingMessageProvider>
          <ErrorBoundary>
            <Switch>
              {[
                ...getPublicRouteComponents(),
                ...getProtectedRouteComponents(),
                ...getPublicFallbackRoutes()
              ]}
            </Switch>
          </ErrorBoundary>
        </FloatingMessageProvider>
      </Suspense>
    </React.StrictMode>
  );
}

const hotRootApp = isDevelopmentEnv() ? hot(RootApp) : RootApp;

export default hotRootApp;
