// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/credit-card-brands/visa.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/credit-card-brands/mastercard.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@media only screen and (max-width: 767px) {\n  .hide-on-sm {\n    display: none; } }\n\n@media only screen and (min-width: 768px) and (max-width: 1150px) {\n  .hide-on-md {\n    display: none; } }\n\n.hidden {\n  display: none; }\n\n.container {\n  width: 100%;\n  max-width: 1060px;\n  margin: auto; }\n  @media only screen and (min-width: 768px) and (max-width: 1150px) {\n    .container {\n      padding: 0 20px; } }\n\n.centered {\n  margin: 0 auto; }\n\n.bullet {\n  display: inline-block;\n  width: 4px;\n  height: 4px;\n  background-color: var(--gray);\n  border-radius: 50%; }\n\n.credit-card-brand {\n  display: block; }\n  .credit-card-brand.visa {\n    width: 25px;\n    height: 14px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat; }\n  .credit-card-brand.mastercard {\n    width: 24px;\n    height: 24px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat; }\n", ""]);
// Exports
module.exports = exports;
