import React, {lazy} from "react";

import ROUTE_NAMES from "./util/routeNames";
import ProtectedRoute from "./component/ProtectedRoute";

const TuneYourFeedPage = lazy(() =>
  import(
    /* webpackChunkName: "tune-your-feed-page" */ "../../onboarding/page/feed/TuneYourFeedPage"
  )
);
const ChooseYourStylesPage = lazy(() =>
  import(
    /* webpackChunkName: "choose-your-styles-page" */ "../../onboarding/page/styles/ChooseYourStylesPage"
  )
);
const FollowSuggestionsPage = lazy(() =>
  import(
    /* webpackChunkName: "follow-suggestions-page" */ "../../onboarding/page/suggestions/FollowSuggestionsPage"
  )
);
const TimelinePage = lazy(() =>
  import(/* webpackChunkName: "timeline-page" */ "../../timeline/page/TimelinePage")
);

const ArtworkDetailsPage = lazy(() =>
  import(
    /* webpackChunkName: "artwork-details-page" */ "../../artwork/page/detail/ArtworkDetailsPage"
  )
);

const ArtworkCreatePage = lazy(() =>
  import(
    /* webpackChunkName: "artwork-create-page-page" */ "../../artwork/create/page/ArtworkCreatePage"
  )
);

const TimelineFollowSuggestionsPage = lazy(() =>
  import(
    /* webpackChunkName: "timeline-follow-suggestions-page" */ "../../timeline/page/follow-suggestions/TimelineFollowSuggestionsPage"
  )
);

const UserProfilePage = lazy(() =>
  import(/* webpackChunkName: "user-profile-page" */ "../../user/page/UserProfilePage")
);

const EditMyProfilePage = lazy(() =>
  import(
    /* webpackChunkName: "edit-my-profile-page" */ "../../user/page/edit/EditMyProfilePage"
  )
);

const UserArtistProfilePage = lazy(() =>
  import(
    /* webpackChunkName: "user-artist-profile-page" */ "../../user/page/artist/UserArtistProfilePage"
  )
);

const MyOrdersPage = lazy(() =>
  import(/* webpackChunkName: "my-orders-page" */ "../../order/page/MyOrdersPage")
);

const MySalesPage = lazy(() =>
  import(/* webpackChunkName: "my-sales-page" */ "../../order/page/MySalesPage")
);

const OrderDetailPage = lazy(() =>
  import(
    /* webpackChunkName: "order-detail-page" */ "../../order/page/detail/OrderDetailPage"
  )
);

function getProtectedRouteComponents() {
  return [
    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.TUNE_YOUR_FEED}`}
      exact={true}
      path={ROUTE_NAMES.ONBOARDING.TUNE_YOUR_FEED}>
      <TuneYourFeedPage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.CHOOSE_YOUR_STYLES}`}
      exact={true}
      path={ROUTE_NAMES.ONBOARDING.CHOOSE_YOUR_STYLES}>
      <ChooseYourStylesPage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.FOLLOW_SUGGESTIONS}`}
      exact={true}
      path={ROUTE_NAMES.ONBOARDING.FOLLOW_SUGGESTIONS}>
      <FollowSuggestionsPage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.TIMELINE.ROOT}`}
      exact={true}
      path={ROUTE_NAMES.TIMELINE.ROOT}>
      <TimelinePage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.TIMELINE.FOLLOW_SUGGESTIONS}`}
      exact={true}
      path={ROUTE_NAMES.TIMELINE.FOLLOW_SUGGESTIONS}>
      <TimelineFollowSuggestionsPage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.ARTWORK.CREATE}`}
      exact={true}
      path={ROUTE_NAMES.ARTWORK.CREATE}>
      <ArtworkCreatePage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.ARTWORK.DETAIL}`}
      exact={true}
      path={ROUTE_NAMES.ARTWORK.DETAIL}>
      <ArtworkDetailsPage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.USER.ME}`}
      exact={true}
      path={ROUTE_NAMES.USER.ME}>
      <UserProfilePage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.USER.EDIT_PROFILE}`}
      exact={true}
      path={ROUTE_NAMES.USER.EDIT_PROFILE}>
      <EditMyProfilePage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.USER.MY_ARTIST_PROFILE}`}
      exact={true}
      path={ROUTE_NAMES.USER.MY_ARTIST_PROFILE}>
      <UserArtistProfilePage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.USER.ARTIST_PROFILE}`}
      exact={true}
      path={ROUTE_NAMES.USER.ARTIST_PROFILE}>
      <UserArtistProfilePage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.USER.PROFILE}`}
      exact={true}
      path={ROUTE_NAMES.USER.PROFILE}>
      <UserProfilePage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.ORDER.ROOT}`}
      exact={true}
      path={ROUTE_NAMES.ORDER.ROOT}>
      <MyOrdersPage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.SALE.ROOT}`}
      exact={true}
      path={ROUTE_NAMES.SALE.ROOT}>
      <MySalesPage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.ORDER.DETAIL}`}
      exact={true}
      path={ROUTE_NAMES.ORDER.DETAIL}>
      <OrderDetailPage />
    </ProtectedRoute>,

    <ProtectedRoute
      key={`route:=${ROUTE_NAMES.SALE.DETAIL}`}
      exact={true}
      path={ROUTE_NAMES.SALE.DETAIL}>
      <OrderDetailPage perspective={"seller"} />
    </ProtectedRoute>,

    <ProtectedRoute key={`route:=${ROUTE_NAMES.ROOT}`} path={ROUTE_NAMES.ROOT} />
  ];
}

export default getProtectedRouteComponents;
